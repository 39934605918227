import { ReactNode } from "react";

import { Box, Toolbar } from "@mui/material";

import { useAuth } from "shared/providers/AuthProvider";

import Navbar from "./Navbar/Navbar";

export default function Dashboard({
  children,
  fullWidth,
}: {
  children: ReactNode;
  fullWidth?: boolean;
}) {
  const { currentUser } = useAuth();
  if (!currentUser) return null;

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "auto",
          overscrollBehavior: "none",
        }}
      >
        <Toolbar />
        <Box sx={{ flexGrow: 1, m: fullWidth ? 0 : 3 }}>{children}</Box>
      </Box>
    </Box>
  );
}
