import { MutableRefObject, useMemo, useRef } from "react";

import { Box } from "@mui/material";
import {
  DataGridPro,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridApiPro,
  GridCallbackDetails,
  GridColDef,
  GridCsvExportMenuItem,
  GridFilterModel,
  GridGroupingColDefOverride,
  GridGroupNode,
  GridLogicOperator,
  GridPaginationModel,
  GridPinnedColumnFields,
  GridProSlotsComponent,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { GridProSlotProps } from "@mui/x-data-grid-pro/models/gridProSlotProps";

type Pagination = {
  enabled: boolean;
  rowCount: number | undefined;
  pageSizeOptions: number[];
  model: GridPaginationModel;
  onPaginationModelChange: (model: GridPaginationModel) => void;
};

type ToolbarFilter = {
  handleFilterChange: (model: GridFilterModel) => void;
  enableColumnFilter?: boolean;
  singleFilter?: boolean;
  oneFilterPerColumn?: boolean;
};

type TreeData = {
  groupingColDef: GridGroupingColDefOverride;
  getTreeDataPath: (rows: GridValidRowModel) => readonly string[];
  isGroupExpandedByDefault?: (node: GridGroupNode) => boolean;
};

function Toolbar(props: GridToolbarProps) {
  const { csvOptions, enableColumnFilter } = props;

  return (
    <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
      <Box pt={1} px={1} justifyContent={"flex-end"}>
        <GridToolbarQuickFilter variant="outlined" size="small" />
      </Box>
      {enableColumnFilter && <GridToolbarFilterButton />}
      {csvOptions && (
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem options={{ fileName: csvOptions.fileName }} />
        </GridToolbarExportContainer>
      )}
    </GridToolbarContainer>
  );
}

// The following two functions are for only showing one filter per column in the filter panel.
// Refer to: https://mui.com/x/react-data-grid/filtering/multi-filters/#one-filter-per-column
const filterColumns = ({
  field,
  columns,
  currentFilters,
}: FilterColumnsArgs) => {
  const filteredFields = currentFilters?.map((item) => item.field);
  return columns
    .filter(
      (colDef) =>
        colDef.filterable &&
        (colDef.field === field || !filteredFields.includes(colDef.field))
    )
    .map((column) => column.field);
};

const getColumnForNewFilter = ({
  currentFilters,
  columns,
}: GetColumnForNewFilterArgs) => {
  const filteredFields = currentFilters?.map(({ field }) => field);
  const columnForNewFilter = columns
    .filter(
      (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
    )
    .find((colDef) => colDef.filterOperators?.length);
  return columnForNewFilter?.field ?? null;
};

export default function DataGridTable({
  apiRef,
  columns,
  rows,
  loading,
  pagination,
  handleSortChange,
  handleSelectionChange,
  toolbarFilter,
  downloadFileName,
  pinnedColumns,
  treeData,
  handleRowUpdate,
}: {
  apiRef: MutableRefObject<GridApiPro>;
  columns: GridColDef[];
  rows: GridRowsProp;
  loading: boolean;
  pagination?: Pagination;
  handleSortChange?: (model: GridSortModel) => void;
  handleSelectionChange?: (
    model: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
  toolbarFilter?: ToolbarFilter;
  downloadFileName?: string;
  pinnedColumns?: GridPinnedColumnFields;
  treeData?: TreeData;
  handleRowUpdate?: (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel
  ) => GridValidRowModel | Promise<GridValidRowModel>;
}) {
  const rowCountRef = useRef(pagination?.rowCount || 0);

  // If the value rowCount becomes undefined during loading, the current page is reset to zero.
  // To avoid this, we memoize the rowCount value to ensure it doesn't change during loading.
  // Refer to: https://mui.com/x/react-data-grid/pagination/#server-side-pagination
  const rowCount = useMemo(() => {
    const currentCount = pagination?.rowCount;
    if (currentCount !== undefined) {
      rowCountRef.current = currentCount;
    }

    return rowCountRef.current;
  }, [pagination?.rowCount]);

  const slots: Partial<GridProSlotsComponent> = {};
  const slotProps: GridProSlotProps = {};
  if (pagination) {
    slotProps.pagination = {
      showFirstButton: true,
      showLastButton: true,
    };
  }
  if (toolbarFilter || downloadFileName) {
    slots.toolbar = Toolbar;

    slotProps.toolbar = {
      showQuickFilter: Boolean(toolbarFilter?.handleFilterChange),
      csvOptions: downloadFileName ? { fileName: downloadFileName } : undefined,
      enableColumnFilter: toolbarFilter?.enableColumnFilter,
    };

    slotProps.filterPanel = {
      disableAddFilterButton: toolbarFilter?.singleFilter, // Disable the default "Add Filter" button in the filter panel
    };

    if (toolbarFilter?.oneFilterPerColumn) {
      // Limit to only one filter per column with the _and operator only.
      slotProps.filterPanel = {
        filterFormProps: {
          filterColumns,
        },
        getColumnForNewFilter,
        logicOperators: [GridLogicOperator.And],
      };
    }
  }

  return (
    // The DataGridPro component is wrapped in a Box with flex to apply "autoHeight" styling.
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <DataGridPro
        apiRef={apiRef}
        initialState={{ pinnedColumns: pinnedColumns }}
        rows={rows}
        columns={columns}
        loading={loading}
        disableRowSelectionOnClick
        pagination={pagination?.enabled}
        paginationMode={pagination ? "server" : "client"}
        rowCount={pagination ? rowCount : undefined}
        pageSizeOptions={pagination?.pageSizeOptions}
        paginationModel={pagination?.model}
        onPaginationModelChange={pagination?.onPaginationModelChange}
        slots={slots}
        slotProps={slotProps}
        sortingMode={"server"}
        disableColumnSorting={!handleSortChange}
        onSortModelChange={handleSortChange}
        disableColumnFilter={!toolbarFilter?.enableColumnFilter}
        filterMode={!treeData ? "server" : "client"} // Server side filtering is not supported with tree data
        onFilterModelChange={toolbarFilter?.handleFilterChange}
        autosizeOnMount
        autosizeOptions={{ expand: true }}
        onColumnVisibilityModelChange={async () => {
          await apiRef.current.autosizeColumns({ expand: true });
        }}
        checkboxSelection={Boolean(handleSelectionChange)}
        keepNonExistentRowsSelected={Boolean(handleSelectionChange)}
        onRowSelectionModelChange={handleSelectionChange}
        treeData={Boolean(treeData)}
        getTreeDataPath={treeData?.getTreeDataPath}
        groupingColDef={treeData?.groupingColDef}
        isGroupExpandedByDefault={treeData?.isGroupExpandedByDefault}
        processRowUpdate={handleRowUpdate}
        sx={{
          "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
            "&:empty::before": {
              content: '"--"',
            },
          },
        }}
      />
    </Box>
  );
}
