import { Box, Button, Stack, Typography } from "@mui/material";
import { useRouter } from "next/router";

import NeedHelpContactSupport from "./EmptyState/NeedHelpContactSupport";

interface ErrorMessageProps {
  resourceName: string;
}

const ErrorMessage = ({ resourceName }: ErrorMessageProps) => {
  const router = useRouter();

  return (
    <Box mt={10}>
      <Stack spacing={1}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h3" textAlign="center">
            We were unable to retrieve your {resourceName}.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" textAlign="center">
            Please&nbsp;
            <Button onClick={() => router.reload()} sx={{ px: 0.2 }}>
              <Typography
                sx={{ textDecoration: "underline" }}
                variant="body1"
                textAlign="center"
              >
                refresh
              </Typography>
            </Button>
            &nbsp;the page or log out and log back in.
          </Typography>
        </Box>
      </Stack>
      <Box mt={10} display="flex" justifyContent="center">
        <NeedHelpContactSupport />
      </Box>
    </Box>
  );
};

export default ErrorMessage;
