import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

type ShopSearchModel = {
  search: string;
};

export function useShopSearch() {
  const router = useRouter();
  const params = useSearchParams();
  const searchTerm = params.get("search") ?? "";

  const shopSearch = {
    search: searchTerm,
  };

  const updateShopSearch = (newSearch: ShopSearchModel) => {
    const newParams = new URLSearchParams(params);

    for (const [key, value] of Object.entries(newSearch)) {
      if (value) {
        newParams.set(key, value.toString());
      } else {
        newParams.delete(key);
      }
    }

    router.push({
      query: newParams.toString(),
    });
  };

  return {
    shopSearch,
    updateShopSearch,
  };
}
